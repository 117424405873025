import {
  Connection,
  IBatchQueryOptions,
  IBatchQueryParams,
  IBatchUpdateChanges,
  ICommonOptions,
  ICreateEntityOptions,
  IDeleteEntityOptions,
  IEntity,
  IGetEntityAttributeOptions,
  IGetEntityAttributesOptions,
  IGetEntityAttributeValueOptions,
  IGetEntityOptions,
  IListEntitiesOptions,
  IUpdateEntityAttributesOptions
} from "ngsijs";

export const bindCreateEntity = (
  getConn: () => Promise<Connection>
): Connection["v2"]["createEntity"] => {
  return function createEntity(entity: IEntity, options: ICreateEntityOptions) {
    return getConn().then((c) => c.v2.createEntity(entity, options));
  };
};

export const bindListEntities = (
  getConn: () => Promise<Connection>
): Connection["v2"]["listEntities"] => {
  return function listEntities(options: IListEntitiesOptions) {
    return getConn().then((c) => c.v2.listEntities(options));
  };
};

export const bindGetEntity = (
  getConn: () => Promise<Connection>
): Connection["v2"]["getEntity"] => {
  return function getEntity(options: IGetEntityOptions) {
    return getConn().then((c) => c.v2.getEntity(options));
  };
};

export const bindGetEntityAttributes = (
  getConn: () => Promise<Connection>
): Connection["v2"]["getEntityAttributes"] => {
  return function getEntityAttributes(options: IGetEntityAttributesOptions) {
    return getConn().then((c) => c.v2.getEntityAttributes(options));
  };
};

export const bindGetEntityAttribute = (
  getConn: () => Promise<Connection>
): Connection["v2"]["getEntityAttribute"] => {
  return function getEntityAttribute(options: IGetEntityAttributeOptions) {
    return getConn().then((c) => c.v2.getEntityAttribute(options));
  };
};

export const bindGetEntityAttributeValue = (
  getConn: () => Promise<Connection>
): Connection["v2"]["getEntityAttributeValue"] => {
  return function getEntityAttribute(options: IGetEntityAttributeValueOptions) {
    return getConn().then((c) => c.v2.getEntityAttributeValue(options));
  };
};

export const bindUpdateEntityAttributes = (
  getConn: () => Promise<Connection>
): Connection["v2"]["updateEntityAttributes"] => {
  return function updateEntityAttributes(
    entity: Pick<IEntity, "id" | "type"> & Partial<IEntity>,
    options: IUpdateEntityAttributesOptions
  ) {
    return getConn().then((c) => c.v2.updateEntityAttributes(entity, options));
  };
};

export const bindDeleteEntity = (
  getConn: () => Promise<Connection>
): Connection["v2"]["deleteEntity"] => {
  return function deleteEntity(options: IDeleteEntityOptions) {
    return getConn().then((c) => c.v2.deleteEntity(options));
  };
};

export const bindBatchQuery = (
  getConn: () => Promise<Connection>
): Connection["v2"]["batchQuery"] => {
  return function batchQuery(
    query: IBatchQueryParams,
    options: IBatchQueryOptions
  ) {
    return getConn().then((c) => c.v2.batchQuery(query, options));
  };
};

export const bindBatchUpdate = (
  getConn: () => Promise<Connection>
): Connection["v2"]["batchUpdate"] => {
  return function batchUpdate(
    changes: IBatchUpdateChanges,
    options: ICommonOptions
  ) {
    return getConn().then((c) => c.v2.batchUpdate(changes, options));
  };
};

export const bindApi = (
  getConn: () => Promise<Connection>
): Pick<
  Connection["v2"],
  | "createEntity"
  | "listEntities"
  | "getEntity"
  | "getEntityAttributes"
  | "getEntityAttribute"
  | "getEntityAttributeValue"
  | "updateEntityAttributes"
  | "deleteEntity"
  | "batchQuery"
  | "batchUpdate"
> => {
  return {
    createEntity: bindCreateEntity(getConn),
    listEntities: bindListEntities(getConn),
    getEntity: bindGetEntity(getConn),
    getEntityAttributes: bindGetEntityAttributes(getConn),
    getEntityAttribute: bindGetEntityAttribute(getConn),
    getEntityAttributeValue: bindGetEntityAttributeValue(getConn),
    updateEntityAttributes: bindUpdateEntityAttributes(getConn),
    deleteEntity: bindDeleteEntity(getConn),
    batchQuery: bindBatchQuery(getConn),
    batchUpdate: bindBatchUpdate(getConn)
  };
};
