import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import type { IEntity } from "ngsijs";

enum HubMethods {
  Register = "Register"
}

enum HubEvents {
  Notify = "Notify"
}

export class NotificationSocket {
  private _clients = new Map<string, Set<(entity: any) => void>>();
  private _connection: HubConnection | null = null;
  private _destroyed = false;
  private _startPromise = Promise.resolve();

  private onMessage(json: string): void {
    if (this._destroyed) {
      return;
    }
    try {
      const message: unknown = JSON.parse(json);
      if (typeof message === "object" && message !== null) {
        if ("data" in message) {
          const entities = (message as { data: IEntity[] }).data;
          entities.forEach((entity: IEntity) => {
            const typeListeners = this._clients.get(entity.type);
            if (typeListeners) {
              typeListeners.forEach((notify) => notify(entity));
            }
          });
        }
      }
    } catch (e) {
      console.error("Unexpected message", e);
    }
  }

  constructor(private url: string) {}

  get destroyed(): boolean {
    return this._destroyed;
  }

  addListener<T extends IEntity>(
    type: T["type"],
    func: (entity: T) => void
  ): void {
    if (this._destroyed) {
      return;
    }
    let set = this._clients.get(type);
    if (!set) {
      set = new Set();
      this._clients.set(type, set);
    }
    set.add(func);
  }

  removeListener<T extends IEntity>(
    type: T["type"],
    func: (entity: T) => void
  ): void {
    if (this._destroyed) {
      return;
    }
    const set = this._clients.get(type);
    if (set) {
      set.delete(func);
    }
  }

  open(): void {
    if (this._destroyed) {
      return;
    }
    if (this._connection === null) {
      const connection = new HubConnectionBuilder()
        .withUrl(this.url)
        .withAutomaticReconnect()
        .build();
      connection.on(HubEvents.Notify, this.onMessage.bind(this));
      this._connection = connection;
      this._startPromise = connection.start();
      this._startPromise
        .then(() => connection.invoke(HubMethods.Register))
        .catch((err) => {
          console.error(err);
          this.close();
        });
    }
  }

  close(): void {
    if (this._destroyed) {
      return;
    }
    if (this._connection !== null) {
      const connection = this._connection;
      this._connection = null;
      this._destroyed = true;
      this._clients.clear();
      this._startPromise.then(() => connection.stop());
    } else {
      console.error("Socket is already closed");
    }
  }
}
