abstract class RequestError extends Error {
  public response?: Response;

  constructor(data?: { message?: string; response?: Response }) {
    super(data?.message);
    this.response = data?.response;
  }
}

export const isRequestError = (v: unknown): v is RequestError =>
  v instanceof RequestError;

/**
 * There are problems connecting to the server
 */
export class ConnectionError extends RequestError {
  name = "ConnectionError";
}
export const isConnectionError = (v: unknown): v is ConnectionError =>
  v instanceof ConnectionError;
/**
 * Server could not process data
 */
export class ServerError extends RequestError {
  name = "ServerError";
}
export const isServerError = (v: unknown): v is ServerError =>
  v instanceof ServerError;
/**
 * The format of server's response is unexpected
 */
export class InvalidResponseError extends RequestError {
  name = "InvalidResponseError";
}
export const isInvalidResponseError = (v: unknown): v is InvalidResponseError =>
  v instanceof InvalidResponseError;
/**
 * Server tells that the request format is unexpected
 */
export class InvalidRequestError extends RequestError {
  name = "InvalidRequestError";
}
export const isInvalidRequestError = (v: unknown): v is InvalidRequestError =>
  v instanceof InvalidRequestError;
/**
 * Server tells that the provided data has errors
 */
export class BadRequestError extends RequestError {
  name = "BadRequestError";
}
export const isBadRequestError = (v: unknown): v is BadRequestError =>
  v instanceof BadRequestError;
/**
 * 401 - Authentication is required and has failed or has not yet been provided
 */
export class AuthenticationError extends RequestError {
  name = "AuthenticationError";
}
export const isAuthenticationError = (v: unknown): v is AuthenticationError =>
  v instanceof AuthenticationError;
/**
 * 403 - Authentication is required and has failed or has not yet been provided
 */
export class ForbiddenError extends RequestError {
  name = "ForbiddenError";
}
export const isForbiddenError = (v: unknown): v is ForbiddenError =>
  v instanceof ForbiddenError;
/**
 * 404 - requesting a missing resource
 */
export class NotFoundError extends RequestError {
  name = "NotFoundError";
}
export const isNotFoundError = (v: unknown): v is NotFoundError =>
  v instanceof NotFoundError;
/**
 * Creating a resource that already exists
 */
export class AlreadyExistsError extends RequestError {
  name = "AlreadyExistsError";
}
export const isAlreadyExistsError = (v: unknown): v is AlreadyExistsError =>
  v instanceof AlreadyExistsError;

export type IRequestError =
  | ConnectionError
  | ServerError
  | InvalidRequestError
  | InvalidResponseError
  | BadRequestError
  | AuthenticationError
  | ForbiddenError
  | NotFoundError
  | AlreadyExistsError;
