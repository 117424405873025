export type IValidator<T> = (v: unknown) => v is T;

export const isUndefined = (v: unknown): v is undefined =>
  typeof v === "undefined";
export const isNull = (v: unknown): v is null => v === null;
export const isString = <T extends string>(v: unknown): v is T =>
  typeof v === "string";
export const isNumber = <T extends number>(v: unknown): v is T =>
  typeof v === "number";
export const isBoolean = <T extends boolean>(v: unknown): v is T =>
  typeof v === "boolean";
export const isArray =
  <T>(of: IValidator<T>) =>
  (v: unknown): v is T[] =>
    Array.isArray(v) && v.every(of);
export const isExact =
  <T>(nominal: T) =>
  (v: unknown): v is T =>
    v === nominal;
export const matches =
  (regEx: RegExp) =>
  <T extends string>(v: unknown): v is T =>
    isString(v) && !!v.match(regEx);
export const isUUID = matches(
  /[\da-z]{8}-[\da-z]{4}-[\da-z]{4}-[\da-z]{4}-[\da-z]{12}/i
);
export const isFunction = <T extends (...args: any[]) => any>(
  v: unknown
): v is T => typeof v === "function";
export const isDate = <T extends Date>(v: unknown): v is T => v instanceof Date;

export type IShapeValidators<Shape> = {
  [P in keyof Shape]: IValidator<Shape[P]>;
};
export const isShape = <T extends Record<string, unknown>>(
  validator: IShapeValidators<T>
): IValidator<T> => {
  return (v: unknown): v is T => {
    if (typeof v === "object" && v !== null) {
      return (
        Object.entries(validator) as Array<
          [keyof T, IShapeValidators<T>[keyof T]]
        >
      ).every(([key, validate]) => {
        return validate((v as T)[key]);
      });
    }
    return false;
  };
};

export function refine<T2 extends T, T>(
  validator: IValidator<T>,
  refinement: (v: T) => v is T2
): IValidator<T2>;
export function refine<T>(
  validator: IValidator<T>,
  refinement: (v: T) => boolean
): IValidator<T>;
export function refine<T>(
  validator: IValidator<T>,
  refinement: (v: T) => boolean
): IValidator<T> {
  return (v: unknown): v is T => {
    return validator(v) && refinement(v);
  };
}

export const or = <T1, T2>(
  validator1: IValidator<T1>,
  validator2: IValidator<T2>
): IValidator<T1 | T2> => {
  return (v: unknown): v is T1 | T2 => {
    return validator1(v) || validator2(v);
  };
};
